.root {
    background-color: black;
    overflow: hidden;
    display: grid;
    height: 100%;
    grid-template-columns: 4rem auto 4rem;
    grid-template-rows: 4rem auto 5rem;
    grid-template-areas:
        "header header header"
        ". main ."
        "footer footer footer";
}


.background{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 0;
}

.background-top {
    display: flex;
    align-items: center;
    overflow: hidden;
    /* max-height: 77%; */
    flex-grow: 2;
    width: 100%;
    -webkit-box-shadow: inset 0px -246px 337px -39px rgba(0,0,0,1);
    -moz-box-shadow: inset 0px -246px 337px -39px rgba(0,0,0,1);
    box-shadow: inset 0px -246px 337px -39px rgba(0,0,0,1);
}

.background-top:after{
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 15%, rgba(0,0,0,0) 90%);
}

.background-bottom {
    width: 100%;
    height: 20%;
    flex-shrink: 1;
    background: black;
}

.floatingContent {
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    flex-direction: column;
}

.person {
    position: absolute;
    bottom: 6%;
    padding: 1rem;
    z-index: 1;
}

.mainScroll {
    overflow: auto;
    position: relative;
    height: 96%;
    width: 100%;
    display: flex;
    grid-area: main;
    z-index: 2;
}

.router {
    overflow: auto;
}

.person-name-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
