.person-info-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.person-info {
    padding: 1rem;
    width: 100%;
    background-color: #000000d6;
    text-align: center;
}

.person-info h1 {
    font-size: 3rem;
    text-shadow: 0px 1.5px #00000080;
    font-family: auto;
}

.person-info h2 {
    font-size: 2rem;
    text-shadow: 0px 1.5px #00000080;
    font-family: auto;
}

