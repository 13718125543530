.imprint {
    background-color: #000000d6;
    width: 36rem;
    padding: 1rem;
}


.floatingImprint {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
