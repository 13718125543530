.root {
    margin: 0 auto;
    overflow: hidden;
}

.image {
    width: 100%;
    position: absolute;
    overflow: hidden;
    z-index: -1;
    height: auto;
    object-fit: cover;
    float: left;
}

/* starting ENTER animation */
.imageout-enter {
    z-index: -1;
    position: absolute;
    opacity: 0.01;
    transform: translateX(-100%);
  }

/* ending ENTER animation */
.imageout-enter-active {
    z-index: -1;
    opacity: 1;
    transform: translateX(0%);
    transition: all 5000ms ease-in;
}

/* starting EXIT animation */
.imageout-exit {
    z-index: -1;
    position: absolute;
    opacity: 1;
    transform: translateX(0%);
}

/* ending EXIT animation */
.imageout-exit-active {
    z-index: -1;
    opacity: 0.01;
    transform: translateX(100%);
    transition: all 5000ms ease-in;
}

.imageWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
}
