.media {
    justify-content: center;
    align-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: transparent;
}

.media > a {
    margin: 1rem 0.5rem;
    color: inherit;
}

.media ion-icon {
    font-size: 3rem;
}
