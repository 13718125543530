.footer-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: space-between;
    align-items: center;
    padding: 1rem;
    grid-area: footer;
    z-index: 1;
    background-color: white;
}

.menu-link {
    padding: 0.5rem;
    text-decoration: none;
    color: black;
    font-size: 1.2rem;
}
